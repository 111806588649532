/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useRef, memo } from 'react';
import { Link } from 'gatsby';
import { m } from 'framer-motion';

export const BlogSimpleComponent = (props: any) => {
  const blogWrapper = useRef();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    import('../../Functions/Utilities').then(module => {
      const grid = module.initializeIsotop(blogWrapper.current);
      grid.on('arrangeComplete', () => setLoading(false));
    });
  }, []);
  const style = {
    '--overlay-color': props.overlay,
  };

  return (
    <div className="grid-wrapper">
      <ul
        ref={blogWrapper}
        className={`grid-container ${props.grid ? ` ${props.grid}` : ''}${
          loading ? ' loading' : ''
        }${props.filter === false ? '' : ' mt-28 md:mt-[4.5rem] sm:mt-8'}`}
      >
        <li className="grid-sizer" />
        {props.data.map((item: any) => {
          return (
            <li key={item.id} className="grid-item">
              <m.div
                className="blog-Simple xs:block"
                style={style}
                initial={{ opacity: 0 }}
                whileInView={!loading && { opacity: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, ease: 'easeOut' }}
              >
                <div
                  className="blog-post-image xs:h-[250px] w-full"
                  style={{
                    backgroundImage: `url(${
                      item.node.image ? item.node.image.publicUrl : ''
                    })`,
                  }}
                >
                  <Link
                    aria-label="link"
                    to={`${props.link}${item.node.slug}`}
                  />
                </div>
                <div className="post-details">
                  <Link
                    aria-label="link"
                    to={`/${props.link}/${item.node.slug}`}
                    className="blog-title"
                  >
                    {item.node.title}
                  </Link>
                  <p className="mb-[25px] xl:mb-[25px] md:mb-[20px] xs:mb-[15px]">
                    {item.node.summary.summary}
                  </p>
                  <Link
                    aria-label="link"
                    to={`/${props.link}/${item.node.slug}`}
                    className="blog-author"
                  >
                    Read More
                  </Link>
                </div>
              </m.div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const BlogSimple = memo(BlogSimpleComponent);
export { BlogSimple };
