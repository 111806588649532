/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
import React, { memo } from 'react';

// Libraries
import { Row } from 'react-bootstrap';
import { m } from 'framer-motion';
import { Link } from 'gatsby';

// components
import { Buttons } from 'components';

const IconWithTextComponent = (props: any) => {
  return (
    <Row className={`${props.grid} md:justify-center`}>
      {props.data.map((item: any, i: number) => {
        return (
          <m.div
            key={i}
            className={`col${props.theme ? ` ${props.theme}` : ''}${
              props.className ? ` ${props.className}` : ''
            }`}
            {...{
              ...props.animation,
              transition: {
                delay: i * props.animationDelay,
                ease: props.animationTransition,
                duration: props.animationDuration,
              },
            }}
          >
            <div className="rounded-md w-full">
              {item.img ? (
                <img
                  height={42}
                  width={51}
                  className="inline-block items-center justify-center mb-[30px]"
                  src={item.img}
                  alt="featurebox"
                />
              ) : item.icon ? (
                props.theme === 'icon-with-text-05' ? (
                  <Link aria-label="link for icon" to="/">
                    <i className={`${item.icon} text-[10px]`} />
                  </Link>
                ) : props.theme === 'icon-with-text-03' ? (
                  <i className={`${item.icon} text-[40px]`} />
                ) : (
                  <i className={`${item.icon} text-[10px]`} />
                )
              ) : item.textIcon ? (
                <span className="text-basecolor inline-block icon-text">
                  {item.textIcon}
                </span>
              ) : (
                <span className="text-basecolor inline-block icon-text">{`${
                  i <= 9 ? '0' : ''
                }${i + 1}`}</span>
              )}

              <div className="feature-box-content">
                {item.title && (
                  <span className="font-medium title font-serif">
                    {item.title}
                  </span>
                )}
                {item.content && <p>{item.content}</p>}
                {props.theme === 'icon-with-text-11' ? (
                  <Buttons
                    ariaLabel="iconwithtext"
                    href="#"
                    className="font-medium font-serif uppercase btn-link after:h-[2px] after:bg-darkgray md:text-md md:mb-[15px]"
                    size="xl"
                    color="#232323"
                    title="Read more"
                  />
                ) : (
                  ''
                )}
              </div>
              {(item.linkTitle || item.link) && (
                <Buttons
                  ariaLabel="iconwithtext"
                  className="font-medium font-serif uppercase btn-link after:h-[1px] md:text-md md:mb-[15px] after:bg-basecolor hover:text-basecolor"
                  to={item.link}
                  title={item.linkTitle}
                />
              )}
            </div>
          </m.div>
        );
      })}
    </Row>
  );
};

IconWithTextComponent.defaultProps = {
  animationDelay: 0.6,
  animationDuration: 0.8,
  animationTransition: 'circOut',
  theme: 'icon-with-text-01',
};

const IconWithText = memo(IconWithTextComponent);

export { IconWithText };
