import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'gatsby';

export const HeroBanner = ({ data }: any) => {
  const {
    bannerCtaLink,
    bannerCtaText,
    bannerImage,
    bannerTagline,
    bannerTitle,
  } = data;
  return (
    <section className="cover-background relative">
      <img
        src={bannerImage.publicUrl}
        alt="hero banner"
        className=" h-full w-full absolute top-0 left-0 object-cover"
      />
      <div className="absolute h-full w-full opacity-20 top-0 left-0 bg-darkslateblue" />
      <Container className="relative">
        <Row className="items-center h-[95vh] md:h-[800px] sm:h-[500px]">
          <Col xl={5} lg={6} md={7} sm={10} className="font-serif font-medium">
            <h2 className=" text-white leading-[65px] tracking-[-1px] mb-[65px] sm:leading-[50px] sm:mb-[25px]">
              {bannerTitle}
            </h2>
            <div className="text-md uppercase tracking-[2px] flex">
              <span className="w-[40px] h-[1px] bg-[#F67B0F] self-center mr-[25px]" />
              <span className="text-[#F67B0F]">{bannerTagline}</span>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="flex flex-row justify-end w-[750px] absolute right-0 bottom-0 z-[1] sm:relative sm:w-full">
        <Row className="items-center justify-end mx-0 w-full">
          <Col
            sm={9}
            className="items-center flex bg-[#F67B0F] h-full py-12 px-20 xs:px-12"
          >
            <h3 className="heading-5 font-serif text-white font-light m-0">
              {bannerCtaText}
            </h3>
          </Col>
          <Col xs={3} className="p-0 h-full lg:hidden">
            <Link
              aria-label="link contact classic"
              to={bannerCtaLink}
              className="text-center flex items-center justify-center bg-[#f9f6f3] h-full"
            >
              <i className="line-icon-Arrow-OutRight text-[60px] text-[#F67B0F]" />
            </Link>
          </Col>
        </Row>
      </div>
    </section>
  );
};
