/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';
import { Helmet } from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import { useLocation } from '@reach/router';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

interface SeoProps {
  description?: any;
  title: string;
  image?: any;
}

const Seo = ({ title, description, image }: SeoProps) => {
  const { pathname } = useLocation();
  return (
    <StaticQuery
      query={seoDetailsQuery}
      render={data => {
        const {
          site: {
            siteMetadata: { defaultTitle, siteUrl },
          },
          contentfulGlobal: {
            seoTitle: defaultMetaTitle,
            seoDescription: defaultMetaDescription,
            seoImage: shareImageURL,
          },
        } = data;

        const seoTitle = title || defaultMetaTitle || defaultTitle;
        const metaUrl = `${siteUrl}${pathname}`;
        const metaImage = image
          ? `${siteUrl}${image}`
          : shareImageURL && `${siteUrl}${shareImageURL?.publicURL}`;
        const canonical = pathname ? `${siteUrl}${pathname}` : null;

        const rawContent = description.raw || defaultMetaDescription.raw;
        const seoDescription = documentToHtmlString(JSON.parse(rawContent));
        return (
          <Helmet
            htmlAttributes={{
              lang: 'en',
            }}
            title={seoTitle}
            titleTemplate={`%s |  ${defaultTitle}`}
            link={
              canonical
                ? [
                    {
                      rel: 'canonical',
                      href: canonical,
                    },
                  ]
                : []
            }
            meta={[
              {
                name: 'description',
                content: seoDescription,
              },
              {
                name: 'image',
                content: metaImage,
              },
              {
                property: 'og:title',
                content: seoTitle,
              },
              {
                property: 'og:description',
                content: seoDescription,
              },
              {
                property: 'og:type',
                content: 'website',
              },
              {
                name: 'og:image:width',
                content: 200,
              },
              {
                name: 'og:image:height',
                content: 150,
              },
              {
                property: 'og:url',
                content: metaUrl,
              },
              {
                name: 'twitter:card',
                content: 'summary',
              },
              {
                name: 'twitter:title',
                content: seoTitle,
              },
              {
                name: 'twitter:description',
                content: seoDescription,
              },
              {
                name: 'twitter:image',
                content: metaImage,
              },
            ].concat(
              metaImage
                ? [
                    {
                      property: `og:image`,
                      content: metaImage,
                    },
                    {
                      property: `og:image:alt`,
                      content: seoTitle,
                    },
                  ]
                : []
            )}
          />
        );
      }}
    />
  );
};

export { Seo };

const seoDetailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        siteUrl
      }
    }
    contentfulGlobal {
      seoTitle
      seoImage {
        publicUrl
      }
      seoDescription {
        raw
      }
    }
  }
`;
