import React, { FC } from 'react';
import { Seo } from 'components';

export interface PageLayoutProps {
  children: JSX.IntrinsicElements['div'];
  title: string;
  description: any;
  image: any;
}

export const PageLayout: FC<PageLayoutProps> = ({
  children,
  title,
  description,
  image,
}) => {
  return (
    <>
      <Seo title={title} description={description} image={image} />
      {children}
    </>
  );
};
