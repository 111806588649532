exports.components = {
  "component---src-domain-about-us-tsx": () => import("./../../../src/domain/about-us.tsx" /* webpackChunkName: "component---src-domain-about-us-tsx" */),
  "component---src-domain-career-tsx": () => import("./../../../src/domain/career.tsx" /* webpackChunkName: "component---src-domain-career-tsx" */),
  "component---src-domain-consulting-tsx": () => import("./../../../src/domain/consulting.tsx" /* webpackChunkName: "component---src-domain-consulting-tsx" */),
  "component---src-domain-contact-us-tsx": () => import("./../../../src/domain/contact-us.tsx" /* webpackChunkName: "component---src-domain-contact-us-tsx" */),
  "component---src-domain-latest-from-us-tsx": () => import("./../../../src/domain/latest-from-us.tsx" /* webpackChunkName: "component---src-domain-latest-from-us-tsx" */),
  "component---src-domain-what-we-do-tsx": () => import("./../../../src/domain/what-we-do.tsx" /* webpackChunkName: "component---src-domain-what-we-do-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-template-article-tsx": () => import("./../../../src/template/article.tsx" /* webpackChunkName: "component---src-template-article-tsx" */),
  "component---src-template-service-tsx": () => import("./../../../src/template/service.tsx" /* webpackChunkName: "component---src-template-service-tsx" */)
}

