/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { m } from 'framer-motion';
import { renderRichText } from 'gatsby-source-contentful/rich-text';

import { fadeIn } from '../../Functions/GlobalAnimations';

export const AboutIntro = ({ data }: any) => {
  const { introTitle, introTagline, introDescription, introImage } = data;
  return (
    <section id="about" className="py-[80px] md:py-[60px] sm:py-[40px]">
      <Container>
        <Row className="items-center md:justify-center">
          <Col
            xs={12}
            lg={5}
            md={9}
            className="lg:text-start md:mb-28 md:mx-auto md:text-center"
          >
            <span className="inline-block mb-[20px] font-medium text-base font-serif uppercase text-start text-[#0349A6] md:block">
              {introTagline}{' '}
            </span>
            <h4 className="mb-[25px] font-semibold text-darkgray font-serif mx-0 w-[95%] text-start lg:mb-[15px]">
              {introTitle}{' '}
            </h4>
            <p className="w-[80%] lg:w-[95%] mb-[25px] sm:mb-[15px] text-start">
              {renderRichText(introDescription)}
            </p>
          </Col>
          <Col
            lg={7}
            md={9}
            xs={12}
            className="px-[55px] md:py-0 md:px-0 md:pl-[5px] sm:pr-[50px]"
          >
            <m.figure className="image-back-offset-shadow about w-100">
              <m.img
                width={582.5}
                height={44.16}
                className="rounded-[6px] w-auto h-auto"
                src={introImage.publicUrl}
                alt=""
                data-no-retina=""
                initial={{ opacity: 0, x: '0', y: '0' }}
                whileInView={{ opacity: 1, x: '10px', y: '-20px' }}
                viewport={{ once: true }}
                transition={{ delay: 0.4, easing: 'linear' }}
              />
              <m.span
                className="rounded-[20px]"
                {...{ ...fadeIn, animate: { x: '35px', y: '5px' } }}
              />
            </m.figure>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
