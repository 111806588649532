/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { memo } from 'react';

// Libraries
import { useField } from 'formik';

export const Input = memo(
  ({ label, labelClass, className, showErrorMsg, ...props }: any) => {
    const [field, meta] = useField(props);

    return (
      <label
        className={`block relative ${
          meta.touched && meta.error ? ' errors-danger' : ''
        }${labelClass ? ` ${labelClass}` : ''}`}
      >
        {label}
        <input
          className={`${className}${
            meta.touched && meta.error ? ' errors-danger' : ''
          }`}
          {...field}
          {...props}
        />
        {meta.touched && meta.error && showErrorMsg ? (
          <span className="text-sm text-error block mt-[5px]">
            {meta.error}
          </span>
        ) : null}
      </label>
    );
  }
);

export const TextArea = memo(
  ({ label, labelClass, className, showErrorMsg, ...props }: any) => {
    const [field, meta] = useField(props);

    return (
      <label
        className={`block relative${
          meta.touched && meta.error ? ' errors-danger' : ''
        }${labelClass ? ` ${labelClass}` : ''}`}
      >
        {label}
        <textarea
          className={`${className}${
            meta.touched && meta.error ? ' errors-danger' : ''
          }`}
          {...field}
          {...props}
        />
        {meta.touched && meta.error && showErrorMsg ? (
          <span className="text-sm text-error block mt-[5px]">
            {meta.error}
          </span>
        ) : null}
      </label>
    );
  }
);

export const Checkbox = memo(
  ({ label, labelClass, className, children, ...props }: any) => {
    const [field, meta] = useField(props);
    return (
      <label
        className={`block relative${
          meta.touched && meta.error ? ' errors-danger' : ''
        }${labelClass ? ` ${labelClass}` : ''}`}
      >
        <input
          type="checkbox"
          className={`${className}${
            meta.touched && meta.error ? ' errors-danger' : ''
          }`}
          {...field}
          {...props}
        />
        {children}
      </label>
    );
  }
);

export const Select = memo(({ label, ...props }: any) => {
  const [field, meta] = useField(props);
  return (
    <div className={props.labelClass}>
      <select {...field} {...props} className={props.className} />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </div>
  );
});

export const FileUpload = memo(({ fileRef, setFieldValue, ...props }: any) => {
  const [field, meta] = useField(props);
  return (
    <div className="mb-4">
      <div className="flex flex-col items-start  w-full border-[1px] border-solid border-[#dfdfdf] px-4 pb-3">
        <label
          htmlFor="custom-input"
          className="block text-sm text-slate-500 mr-4 py-2"
        >
          Resume
        </label>
        <input
          type="file"
          id={props.name}
          {...field}
          multiple={false}
          ref={fileRef}
          value={undefined}
          onChange={event => {
            setFieldValue('attachedFile', event.currentTarget.files[0]);
          }}
          accept=".pdf"
        />
      </div>
      <p
        className="my-1 text-sm text-gray-500 dark:text-gray-300"
        id="file_input_help"
      >
        Only PDF (Max Size. 5MB).
      </p>
      {meta.touched && meta.error ? (
        <div style={{ color: 'red' }}>{meta.error}</div>
      ) : null}
    </div>
  );
});
