/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { m } from 'framer-motion';
import { Buttons } from 'components';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import moment from 'moment';

import { fadeIn } from '../../Functions/GlobalAnimations';

export const HomeAbout = ({ data }: any) => {
  const getNumberOfYearsFromGivenYear = (givenYear: any) => {
    const now = moment();
    const givenYearMoment = moment(givenYear);
    const diffInYears = now.diff(givenYearMoment, 'years');

    return diffInYears;
  };

  const numberOfYears = getNumberOfYearsFromGivenYear(
    data.aboutCompanyIncorporated
  );
  return (
    <m.section
      className="py-[100px] lg:py-[80px] md:py-[50px] sm:py-[40px]"
      {...fadeIn}
    >
      <Container>
        <Row className="items-center justify-center md:items-start sm:text-center">
          <Col lg={4} md={6} className="md:mb-[50px]">
            <m.div
              className="inline-block text-center w-[300px] py-14 px-[15px] relative"
              {...{ ...fadeIn, transition: { delay: 0.2 } }}
            >
              <div className="border-r-0 border-solid	border-[10px] border-[#0349A6] h-full w-[67px] block absolute bottom-0 left-0" />
              <h3 className="text-[80px] leading-[72px] mb-0 mr-[15px] font-semibold tracking-[-5px] text-darkgray font-serif inline-block align-middle">
                {numberOfYears}
              </h3>
              <div className="w-[40%] leading-[20px] font-medium text-darkgray text-xmd font-serif text-left relative inline-block align-middle lg:w-[50%] sm:w-[35%]">
                Years experience working
              </div>
              <div className="border-l-0 border-solid	border-[10px] border-[#0349A6] h-full w-[67px] block absolute bottom-0 right-0" />
            </m.div>
          </Col>
          <Col lg={3} md={4}>
            <m.div {...{ ...fadeIn, transition: { delay: 0.4 } }}>
              <span className="mb-[20px] text-md font-serif uppercase block">
                Since {data.aboutCompanyIncorporated}
              </span>
              <span className="w-[85%] leading-[34px] font-medium text-darkgray text-xlg font-serif block lg:leading-0 lg:text-lg sm:w-full sm:mb-[15px]">
                {data.aboutTagline}
              </span>
            </m.div>
          </Col>
          <Col lg={5} md={10}>
            <m.div {...{ ...fadeIn, transition: { delay: 0.6 } }}>
              <p className="w-[84%] mb-[25px] block lg:w-full">
                {renderRichText(data.aboutDescription)}
              </p>

              <Buttons
                to={data.aboutCtaLink}
                className="font-medium font-serif uppercase btn-link text-md after:bg-[#F67B0F] after:h-[1px] after:!-bottom-[5px] hover:text-darkgray md:text-md md:mb-[15px]"
                color="#F67B0F"
                title={data.aboutCtaText}
                size="xl"
              />
            </m.div>
          </Col>
        </Row>
      </Container>
    </m.section>
  );
};
