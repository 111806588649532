/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { Suspense, FC, useState, useEffect, useMemo } from 'react';

import { ParallaxProvider } from 'react-scroll-parallax';
import { LazyMotion, domMax, AnimatePresence } from 'framer-motion';
import { useLocation } from '@reach/router';
import retina from 'retinajs';
import { Col, Navbar } from 'react-bootstrap';
import { Link } from 'gatsby';

import {
  Header,
  HeaderNav,
  Menu,
  Footer,
  ScrollToTopButton,
  CTABanner,
} from 'components';
import { Globaldata } from '../query';

import '../assets/css/global.css';
import '../assets/css/icons.css';
import '../assets/css/pages.css';
import 'sass/style.scss';

import GlobalContext from '../context/Context';

export interface LayoutProps {
  children: JSX.IntrinsicElements['div'] | any;
}

const Layout: FC<LayoutProps> = ({ children }: any) => {
  const [headerHeight, setHeaderHeight] = useState(0) as any;
  const [footerHeight, setFooterHeight] = useState(0) as any;
  const [isModalOpen, setIsModalOpen] = useState(false) as any;
  const [customModal, setCustomModal] = useState({
    el: null,
    isOpen: false,
  }) as any;

  const location = useLocation();

  // RetinaJS
  useEffect(() => {
    window.addEventListener('load', retina(document.querySelectorAll('img')));
  }, []);

  useEffect(() => {
    setTimeout(() => {
      import('../Functions/Utilities').then(module => {
        module.SetHeaderMenuPos();
        module.setDocumentFullHeight();
      });
    }, 1000);
  }, [location]);

  useEffect(() => {
    if (isModalOpen === true) {
      document?.querySelector('body')?.classList?.add('overflow-hidden');
    } else {
      document?.querySelector('body')?.classList?.remove('overflow-hidden');
    }
  }, [isModalOpen]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant',
    });
    setFooterHeight(0);
    setCustomModal({
      ...customModal,
      el: null,
      isOpen: false,
    });
  }, [location]);

  const globalProviderValue = useMemo(
    () => ({
      headerHeight,
      setHeaderHeight,
      footerHeight,
      setFooterHeight,
      isModalOpen,
      setIsModalOpen,
      customModal,
      setCustomModal,
    }),
    [
      headerHeight,
      setHeaderHeight,
      footerHeight,
      setFooterHeight,
      isModalOpen,
      setIsModalOpen,
      customModal,
      setCustomModal,
    ]
  );
  const [expanded, setExpanded] = useState(false);
  const globalData = Globaldata();

  const serviceSubMenu = [];
  globalData.allContentfulService.edges.forEach((service: any) => {
    serviceSubMenu.push({
      title: service.node.title,
      link: `/${globalData?.contentfulServicesPage?.slug}/${service.node.slug}`,
      id: service.node.slug,
      dropdown: '',
    });
  });
  serviceSubMenu.push({
    title: globalData.contentfulConsultingPage.title,
    link: `/${globalData?.contentfulServicesPage?.slug}/${globalData.contentfulConsultingPage.slug}`,
    id: globalData.contentfulConsultingPage.slug,
    dropdown: '',
  });
  const menuData = [
    {
      id: 1,
      link: `/${globalData?.contentfulAboutUsPage?.slug}`,
      title: globalData?.contentfulAboutUsPage?.title,
      hasSubmenu: false,
      subMenu: [],
    },
    {
      id: 2,
      link: `/${globalData?.contentfulServicesPage?.slug}`,
      title: globalData?.contentfulServicesPage?.title,
      hasSubmenu: true,
      subMenu: serviceSubMenu,
    },
    {
      id: 3,
      link: `/${globalData?.contentfulCareerPage?.slug}`,
      title: globalData?.contentfulCareerPage?.title,
      hasSubmenu: false,
      subMenu: [],
    },
    {
      id: 4,
      link: `/${globalData?.contentfulLatestEventsPage?.slug}`,
      title: globalData?.contentfulLatestEventsPage?.title,
      hasSubmenu: false,
      subMenu: [],
    },
    {
      id: 5,
      link: `/${globalData?.contentfulContactPage?.slug}`,
      title: globalData?.contentfulContactPage?.title,
      hasSubmenu: false,
      subMenu: [],
    },
  ];

  const footerData = {
    copyrightMessage: globalData?.contentfulGlobal?.copyrightMessage,
    phoneNumber: globalData?.contentfulGlobal?.phoneNumber,
    email: globalData?.contentfulGlobal?.email,
    address: globalData?.contentfulGlobal?.address,
    whatsUpNumber: globalData?.contentfulGlobal?.whatsUpNumber,
    SocialMediaLink: [
      {
        id: 1,
        link: globalData?.contentfulGlobal?.faceBookLink,
        title: 'feather-facebook',
      },
      {
        id: 2,
        link: globalData?.contentfulGlobal?.instagramUrl,
        title: 'feather-instagram',
      },
      {
        id: 3,
        link: globalData?.contentfulGlobal?.linkedInUrl,
        title: 'feather-linkedin',
      },
    ],
    footerMenu: [
      {
        title: 'Quick Links',
        footerNavigation: menuData,
      },
      {
        title: 'What we do',
        footerNavigation: serviceSubMenu,
      },
    ],
  };

  return (
    <LazyMotion features={domMax}>
      <ParallaxProvider>
        <GlobalContext.Provider value={globalProviderValue}>
          <div
            className="App"
            style={{ '--header-height': `${headerHeight}px` }}
          >
            <ScrollToTopButton />
            <AnimatePresence exitBeforeEnter>
              <Suspense fallback={<></>}>
                <Header topSpace={{ desktop: true }} type="reverse-scroll">
                  <HeaderNav
                    theme="light"
                    bg="white"
                    menu="light"
                    expand="lg"
                    className="px-[35px] py-[0px] lg:px-[15px] md:px-0"
                    containerClass="py-[10px] px-[10px]"
                    expanded={expanded}
                  >
                    <Col className="col-auto col-sm-6 col-lg-2 me-auto ps-lg-0">
                      <Link
                        aria-label="header logo"
                        className="flex items-center"
                        to="/"
                      >
                        <Navbar.Brand className="flex items-center p-0 m-0">
                          <img
                            className="default-logo"
                            width={160}
                            height={60}
                            loading="lazy"
                            src="/img/cc-logo.svg"
                            data-rjs="/img/cc-logo.svg"
                            alt="logo"
                          />
                          <img
                            className="alt-logo"
                            width={160}
                            height={60}
                            loading="lazy"
                            src="/img/cc-logo.svg"
                            data-rjs="/img/cc-logo.svg"
                            alt="logo"
                          />
                          <img
                            className="mobile-logo"
                            width={50}
                            height={10}
                            loading="lazy"
                            src="/img/cc-logo-min.svg"
                            data-rjs="/img/cc-logo-min.svg"
                            alt="logo"
                          />
                        </Navbar.Brand>
                      </Link>
                    </Col>
                    <div className="col-auto hidden order-last md:block px-[10px]">
                      <Navbar.Toggle
                        className="md:ml-[10px] sm:ml-0"
                        onClick={() =>
                          setExpanded(expanded ? false : 'expanded')
                        }
                      >
                        <span className="navbar-toggler-line" />
                        <span className="navbar-toggler-line" />
                        <span className="navbar-toggler-line" />
                        <span className="navbar-toggler-line" />
                      </Navbar.Toggle>
                    </div>
                    <Navbar.Collapse className="col-auto px-0 justify-end">
                      <Menu menu={menuData} setExpanded={setExpanded} />
                    </Navbar.Collapse>
                    <Col className="col-auto text-right pe-0">
                      <a
                        href={`mailto:${globalData?.contentfulGlobal?.email}`}
                        aria-label="email"
                        className="pl-[17px] align-middle text-[17px] xs:pl-[15px] text-xl"
                      >
                        <i className="feather-mail text-[#0349A6] font-bold	antialiased" />
                      </a>
                      <a
                        href={`tel:${globalData?.contentfulGlobal?.phoneNumber}`}
                        aria-label="call"
                        className="pl-[17px] align-middle text-[17px] xs:pl-[15px]	text-xl"
                      >
                        <i className="feather-phone	text-[#0349A6]	font-bold	antialiased" />
                      </a>
                      <a
                        href={`https://wa.me/${globalData?.contentfulGlobal?.whatsUpNumber}`}
                        aria-label="call"
                        className="pl-[17px] align-middle text-[17px] xs:pl-[15px]	text-xl"
                      >
                        <i className="fab fa-whatsapp text-[#0349A6]	font-bold	antialiased" />
                      </a>
                    </Col>
                  </HeaderNav>
                </Header>
                <main
                  style={{
                    marginTop: headerHeight,
                    marginBottom: footerHeight,
                  }}
                  className="min-h-[400px]"
                >
                  {children}
                  {location.pathname !== '/contact-us/' && (
                    <CTABanner
                      image={globalData?.contentfulGlobal?.ctaBannerImage}
                      title={globalData?.contentfulGlobal?.ctaBannerButtonTitle}
                      link={globalData?.contentfulGlobal?.ctaBannerButtonLink}
                    />
                  )}
                </main>
                <Footer
                  theme="dark"
                  className="text-slateblue bg-[#003B69]"
                  data={footerData}
                />
              </Suspense>
            </AnimatePresence>
          </div>
        </GlobalContext.Provider>
      </ParallaxProvider>
    </LazyMotion>
  );
};

export default Layout;
