import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { IconWithText } from 'components';

import { fadeIn } from '../../Functions/GlobalAnimations';

export const ServiceCardList = ({ title, list }: any) => {
  return (
    <section className="bg-slate-50	 py-[90px] lg:py-[70px] md:py-[50px] sm:py-[40px]">
      <Container>
        <Row>
          <Col className="mb-[4%]">
            <h6 className="font-serif text-dark text-center font-medium mb-[25px] lg:mb-[15px]">
              {title}
            </h6>
          </Col>
        </Row>
        <Row className="justify-center">
          <Col xs={12} sm={8} md={12}>
            <IconWithText
              grid="row-cols-1 row-cols-lg-3 row-cols-md-2 row-col-sm-8 justify-center gap-y-10"
              theme="icon-with-text-06"
              data={list}
              animation={fadeIn}
              animationDelay={0.2}
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};
