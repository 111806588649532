/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { m } from 'framer-motion';
import { Parallax } from 'react-scroll-parallax';
import { Buttons } from 'components';

import { fadeIn } from '../../Functions/GlobalAnimations';

export const CTABanner = ({ image, link, title }: any) => {
  return (
    <m.div
      className="relative overflow-hidden py-[130px] lg:py-[90px] md:py-[75px] sm:py-[50px] sm:px-[15px]"
      {...fadeIn}
    >
      <Parallax
        className="lg-no-parallax bg-cover cover-background absolute top-[-30px] left-0 h-[100vh] lg:h-[64vh] lg:top-[-75px] lg:!translate-y-0 md:h-[60vh] md:-top-[30px] sm:top-0 sm:h-[56vh] xs:h-[50vh] w-full"
        translateY={[-40, 40]}
        style={{
          backgroundImage: `url(${image?.publicUrl})`,
        }}
      />
      <div className="left-0 top-0 h-full w-full absolute bg-darkslateblue opacity-75" />
      <Container className="relative">
        <Row className="justify-center items-center">
          <Col xl={7} md={8} sm={10} className="md:mb-[30px] sm:mb-[30px]">
            <h4 className="font-serif font-semibold text-white mb-0 sm:text-center">
              {title}
            </h4>
          </Col>
          <Col xl={5} md={4} className="md:flex md:justify-center text-end">
            <Buttons
              ariaLabel="button"
              to={link}
              className="font-medium font-serif uppercase rounded-none lg:mb-[15px] landscape:lg:mb-[15px]"
              themeColor={['#F67B0F', '#F67B0F']}
              size="lg"
              color="#fff"
              title="Contact Us"
            />
          </Col>
        </Row>
      </Container>
    </m.div>
  );
};
