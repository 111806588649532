/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { m } from 'framer-motion';

// Component
import { fadeIn } from '../../Functions/GlobalAnimations';

export const CoreValues = ({ data }: any) => {
  const { coreValuesList, coreValuesTitle } = data;
  return (
    <m.section
      className="py-[80px] md:py-[5px] sm:py-[30px] bg-lightgray"
      {...fadeIn}
    >
      <Container>
        <Row>
          <Col className="mb-[3%]">
            <h6 className="font-serif text-darkgray text-center font-medium mb-[25px] lg:mb-[15px]">
              {coreValuesTitle}
            </h6>
          </Col>
        </Row>
        <Row className="justify-center">
          <Col xl={5} lg={6} md={8}>
            <ul className="list-style-03">
              {coreValuesList.map((item: any, i: any) => {
                return (
                  <m.li
                    key={item}
                    {...{
                      ...fadeIn,
                      transition: {
                        delay: (i + 1) * 0.1,
                        initial: { opacity: 0, x: '100%' },
                        ease: [0.25, 1, 0.5, 1],
                        duration: 1.2,
                      },
                    }}
                  >
                    <i className="feather-check" />
                    {item && <span>{item}</span>}
                  </m.li>
                );
              })}
            </ul>
          </Col>
        </Row>
      </Container>
    </m.section>
  );
};
