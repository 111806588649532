/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { m } from 'framer-motion';
import { renderRichText } from 'gatsby-source-contentful/rich-text';

import { fadeIn } from '../../Functions/GlobalAnimations';

export const BlockQuote = ({ data }: any) => {
  const { missionTitle, missionQuote } = data;
  return (
    <m.section
      className="py-[100px] lg:py-[80px] md:py-[60px] sm:py-[40px]"
      {...fadeIn}
    >
      <Container>
        <Row className="mb-[4%]">
          <Col>
            <h6 className="font-serif text-darkgray text-center font-medium mb-[25px] lg:mb-[15px]">
              {missionTitle}{' '}
            </h6>
          </Col>
        </Row>
        <Row className="justify-center">
          <Col md={8} lg={6} xl={8} className="p-0">
            <m.blockquote className="bg-[#F67B0F] font-medium blockquote-style-01">
              <i className="ti-quote-left"></i>
              <h6>{renderRichText(missionQuote)}</h6>
            </m.blockquote>
          </Col>
        </Row>
      </Container>
    </m.section>
  );
};
