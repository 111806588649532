/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
import React, { memo } from 'react';

// Libraries
import { Link } from 'gatsby';
import { Row } from 'react-bootstrap';
import { m } from 'framer-motion';

const ServicesComponent = (props: any) => {
  return (
    <Row className={props.grid}>
      {props.data.map((item: any, i: number) => {
        return (
          <m.div
            key={item.id}
            className={`col px-[15px]${
              props.className ? ` ${props.className}` : ''
            }`}
            {...{
              ...props.animation,
              transition: { delay: i * 0.2 },
            }}
          >
            <div className={props.theme}>
              <div className="img-wrapper">
                {item.img && (
                  <img
                    height={238}
                    width={360}
                    className="w-full max-w-full align-middle object-cover"
                    src={item.img}
                    alt={item.title}
                  />
                )}
                {props.theme === 'service-style-01' && (
                  <div className="services-box-hover">
                    {(item.icon || item.link) && (
                      <Link
                        aria-label="services"
                        to={item.link ? item.link : '/'}
                      >
                        <i className="fas fa-arrow-right" />
                      </Link>
                    )}
                  </div>
                )}
              </div>
              <div className="service-style">
                {props.theme === 'service-style-03' && (
                  <span className="verticalline" />
                )}
                {item.title && (
                  <span className="title font-medium text-darkgray block font-serif mb-[10px]">
                    {item.title}
                  </span>
                )}
                {item.content && (
                  <div className="service-style-content">{item.content}</div>
                )}
                {props.theme === 'service-style-02' && (
                  <div className="info-service">
                    <Link
                      aria-label="services"
                      to={`${item.url ? `/${props.link}/${item.url}` : '/'}`}
                      className="no-underline font-serif font-medium text-gray-900 text-sm uppercase block"
                    >
                      More Info
                      <i className="fas fa-arrow-right" />
                    </Link>
                  </div>
                )}
                {props.theme === 'service-style-05' && (
                  <div className="flex justify-between align-middle">
                    {item?.experience && (
                      <div className="flex">
                        <span>
                          <i className="solid-icon-Calendar text-[20px]" />
                        </span>
                        <span className="!text-[17px] ml-2 !font-normal inline-block">
                          {item.experience}
                        </span>
                      </div>
                    )}
                    {item?.salary && (
                      <div className="flex align-middle">
                        <span>
                          <i className="solid-icon-Mail-Money text-[20px] inline-block" />
                        </span>
                        <span className="!text-[17px] ml-2 !font-normal inline-block">
                          {item.salary}
                        </span>
                      </div>
                    )}
                    {item?.location && (
                      <div className="flex">
                        <span>
                          <i className="solid-icon-Location-2 text-[20px]" />
                        </span>
                        <span className="!text-[17px] ml-2 !font-normal inline-block">
                          {item?.location}
                        </span>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </m.div>
        );
      })}
    </Row>
  );
};

const Services = memo(ServicesComponent);

export { Services };
