/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useRef } from 'react';
import { Form, Formik } from 'formik';
import { AnimatePresence, m } from 'framer-motion';
import { Col, Container, Row, Alert } from 'react-bootstrap';
import * as Yup from 'yup';
import { Buttons, Input, TextArea } from 'components';

import { resetForm, submitForm } from '../../Functions/Utilities';
import { fadeIn } from '../../Functions/GlobalAnimations';

export const ContactFormStyle03Schema = Yup.object().shape({
  name: Yup.string().required('Field is required.'),
  email: Yup.string().email('Invalid email.').required('Field is required.'),
});

export const ContactForm = ({ formTitle, formTagline, formUrl }: any) => {
  const form = useRef(null);
  const [show, setShow] = useState(true);

  return (
    <m.section className="py-[70px] lg:py-[30px] md:py-[25px]" {...fadeIn}>
      <Container>
        <Row className="justify-center">
          <Col xl={6} lg={7} className="text-center mb-[4.5rem] md:mb-12">
            <span className="font-serif mb-[5px] -tracking-[.5px] text-xmd block">
              {formTagline}
            </span>
            <h4 className="font-serif font-semibold text-darkgray">
              {formTitle}{' '}
            </h4>
          </Col>
          <Col className="col-xl-10 col-sm-12 col-xs-12">
            <Formik
              initialValues={{
                name: '',
                email: '',
                phoneNo: '',
                about: '',
              }}
              validationSchema={ContactFormStyle03Schema}
              onSubmit={async (values, actions) => {
                actions.setSubmitting(true);
                const formData = { ...values, type: 'contact' };
                const response: any = await submitForm(formData, formUrl);
                if (response && !response.err) {
                  resetForm(actions);
                }
              }}
            >
              {({ isSubmitting, status }) => (
                <Form ref={form}>
                  <Row className="row-cols-1 row-cols-md-2">
                    <Col className="mb-16 sm:mb-[25px]">
                      <Input
                        showErrorMsg={false}
                        type="text"
                        name="name"
                        className="py-[15px] px-[20px] text-md w-full border-[1px] border-solid border-[#dfdfdf]"
                        labelClass="mb-[25px]"
                        placeholder="Your name"
                      />
                      <Input
                        showErrorMsg={false}
                        type="email"
                        name="email"
                        className="py-[15px] px-[20px] w-full text-md border-[1px] border-solid border-[#dfdfdf]"
                        labelClass="mb-[25px]"
                        placeholder="Your email address"
                      />
                      <Input
                        showErrorMsg={false}
                        type="tel"
                        name="phoneNo"
                        className="py-[15px] px-[20px] w-full text-md border-[1px] border-solid border-[#dfdfdf]"
                        placeholder="Mobile number"
                      />
                    </Col>
                    <Col className="mb-16 sm:mb-[20px]">
                      <TextArea
                        className="border-[1px] border-solid border-[#dfdfdf] w-full py-[15px] px-[20px] text-md h-[210px] resize-none"
                        rows="6"
                        name="about"
                        placeholder="Your message"
                      />
                    </Col>
                    <Col className="text-left sm:mb-[20px]" />
                    <Col className="text-right sm:text-center">
                      <Buttons
                        type="submit"
                        className={`text-xs tracking-[1px] rounded-none py-[12px] px-[28px] uppercase${
                          isSubmitting ? ' loading' : ''
                        }`}
                        themeColor={['#F67B0F', '#F67B0F']}
                        size="md"
                        color="#fff"
                        title="Send Message"
                      />
                    </Col>
                  </Row>
                  <AnimatePresence>
                    {status && (
                      <Row>
                        <Col xs={12}>
                          <div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                          >
                            {show && (
                              <Alert
                                className="message-box01 mt-[20px] py-[10px]"
                                variant="success"
                                onClose={() => setShow(false)}
                              >
                                Your form has been submitted successfully!
                              </Alert>
                            )}
                          </div>
                        </Col>
                      </Row>
                    )}
                  </AnimatePresence>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    </m.section>
  );
};
