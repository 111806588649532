import { useStaticQuery, graphql } from 'gatsby';

const Globaldata = () => {
  return useStaticQuery(graphql`
    query GlobalQuery {
      contentfulGlobal {
        address {
          raw
        }
        location
        copyrightMessage
        ctaBannerButtonLink
        ctaBannerButtonTitle
        email
        ctaBannerImage {
          publicUrl
        }
        faceBookLink
        instagramUrl
        linkedInUrl
        phoneNumber
        seoTitle
        siteName
        whatsUpNumber
        seoImage {
          gatsbyImageData
        }
        seoDescription {
          raw
        }
      }
      allContentfulService {
        edges {
          node {
            title
            slug
          }
        }
      }
      contentfulConsultingPage {
        title
        slug
      }
      contentfulAboutUsPage {
        title
        slug
      }
      contentfulCareerPage {
        slug
        title
      }
      contentfulContactPage {
        slug
        title
      }
      contentfulLatestEventsPage {
        title
        slug
      }
      contentfulServicesPage {
        slug
        title
      }
    }
  `);
};

export { Globaldata };
