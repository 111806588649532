/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { m } from 'framer-motion';
import { renderRichText } from 'gatsby-source-contentful/rich-text';

import { fadeIn } from '../../Functions/GlobalAnimations';

export const HeadingContent = ({ data }: any) => {
  const { teamTitle, teamDescription } = data;
  return (
    <m.section
      className="py-[100px] lg:py-[80px] md:py-[50px] sm:py-[40px]"
      {...fadeIn}
    >
      <Container>
        <Row className="justify-center">
          <Col className="col-12 col-lg-8">
            <h4 className="font-serif text-dark font-medium lg:mb-[15px] text-center">
              {teamTitle}
            </h4>
            <p>{renderRichText(teamDescription)}</p>
          </Col>
        </Row>
      </Container>
    </m.section>
  );
};
